import React, { useMemo, useState } from "react";
import clsx from "clsx";
import styles from "../styles/MenuButton.module.scss";
import Spacer from "./Spacer";
import { useTranslation } from "react-i18next";
import Link from "next/link";
import { HeaderVariant } from "./Header";
import { useRouter } from "next/router";

export enum Menu {
  None,
  Home,
  About,
  Gallery,
  Faqs,
}

interface Props {
  color?: string;
  hoverColor?: string;
  containerClassName?: string;
  variant: HeaderVariant;
}

const MenuButton: React.FC<Props> = ({
  color = "#073937",
  hoverColor = color,
  containerClassName,
  variant,
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const selected = useMemo(() => {
    switch (router.pathname) {
      case "/":
        return Menu.Home;
      case "/about":
        return Menu.About;
      case "/gallery":
        return Menu.Gallery;
      case "/faqs":
        return Menu.Faqs;
      default: {
        if (router.pathname.startsWith("/gallery/")) {
          return Menu.Gallery;
        }

        return Menu.None;
      }
    }
  }, [router.pathname]);

  const menus = useMemo(
    () => [
      {
        id: Menu.Home,
        text: t("home.text"),
        url: "/",
      },
      {
        id: Menu.About,
        text: t("about.text"),
        url: "/about",
      },
      {
        id: Menu.Gallery,
        text: t("gallery.text"),
        url: "/gallery",
      },
      {
        id: Menu.Faqs,
        text: t("faqs.text"),
        url: "/faqs",
      },
    ],
    [t]
  );
  const [hovered, setHovered] = useState<Menu>();
  const dark = useMemo(() => variant === HeaderVariant.dark, [variant]);

  return (
    <div
      className={clsx("row ai-center", styles.container, containerClassName, {
        [styles.darkContainer]: dark,
      })}
    >
      {menus.map((menu, index) => (
        <React.Fragment key={menu.id}>
          <Link href={menu.url}>
            <a
              className={clsx("row ai-center cursor-pointer", styles.button, {
                [styles.selected]: selected === menu.id,
                [styles.darkButton]: dark,
              })}
              style={{ color: hovered === menu.id ? hoverColor : color }}
              onMouseOver={() => setHovered(menu.id)}
              onMouseLeave={() => setHovered(undefined)}
            >
              {menu.text}
            </a>
          </Link>
          {index !== menus.length - 1 && (
            <Spacer width={16} className={"hideOnTablet"} />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export { MenuButton };
