import { useMoralis, useNativeBalance } from "react-moralis";
import { getEllipsisTxt } from "../utils/formatters";
import Blockie from "./Blockie";
import React, { useContext, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import styles from "styles/Account.module.scss";
import Spacer from "./Spacer";
import { AccountDetailsModal } from "./AccountDetailsModal";
import { RequireConnectWalletButton } from "./RequireConnectWalletButton";
import { getChainById } from "utils/networks";
import { animated, easings, Spring } from "react-spring";
import { useTranslation } from "react-i18next";
import { HeaderVariant } from "./Header";
import { MediaQueryContext } from "../context/mediaQuery/context";

interface Props {
  showConnectButton: boolean;
  variant: HeaderVariant;
}

const Account: React.FC<Props> = ({ showConnectButton, variant }) => {
  const {
    isAuthenticated,
    account,
    isInitialized,
    isWeb3EnableLoading,
    isWeb3Enabled,
    enableWeb3,
  } = useMoralis();
  const [isAddressModalVisible, setIsAddressModalVisible] = useState(false);
  const { data: balance, isLoading } = useNativeBalance({
    // @ts-ignore
    chain: `0x${getChainById(process.env.NEXT_PUBLIC_CHAIN_ID!)}`,
  });
  const { t } = useTranslation();
  const {
    state: { tablet },
  } = useContext(MediaQueryContext);

  useEffect(() => {
    const connectorId = window.localStorage.getItem("connectorId");
    if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading) {
      // @ts-ignore
      enableWeb3({ provider: connectorId }).then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isWeb3Enabled]);

  const dark = useMemo(() => variant === HeaderVariant.dark, [variant]);

  if (isLoading || isWeb3EnableLoading || !isInitialized) return null;

  if (!isAuthenticated || !account) {
    if (!showConnectButton) return null;

    return (
      <RequireConnectWalletButton>
        <div
          className={clsx("cursor-pointer", styles.connectWalletButton, {
            [styles.connectWalletDarkButton]: dark,
          })}
        >
          {t("wallet.connect")}
        </div>
      </RequireConnectWalletButton>
    );
  }

  return (
    <Spring
      from={{
        opacity: 0,
      }}
      to={{ opacity: 1 }}
      config={{ duration: 450, easing: easings.easeOutSine }}
    >
      {(animatedStyles) => {
        return (
          <animated.div style={animatedStyles}>
            <div
              className={clsx(
                styles.account,
                "row ai-center jc-center cursor-pointer"
              )}
              onClick={() => setIsAddressModalVisible(true)}
            >
              <Spacer width={16} />
              <div className={styles.balance}>{balance?.formatted}</div>
              <Spacer width={8} />
              <div className={clsx("row ai-center", styles.accountInfo)}>
                <div>{getEllipsisTxt(account, tablet ? 4 : 6)}</div>
                <Spacer width={16} />
                <Blockie
                  currentWallet
                  scale={3}
                  size={6.4}
                  className={styles.blockie}
                />
              </div>
            </div>

            <AccountDetailsModal
              show={isAddressModalVisible}
              balance={balance?.formatted}
              onClose={() => setIsAddressModalVisible(false)}
            />
          </animated.div>
        );
      }}
    </Spring>
  );
};

export { Account };
