import React, { useCallback, useEffect, useState } from "react";
import { doc, onSnapshot } from "@firebase/firestore";
import { db } from "../utils/firebase";
import { AnnouncementMessage } from "../utils/types";
import styles from "../styles/Announcement.module.scss";
import Spacer from "./Spacer";
import clsx from "clsx";
import Image from "next/image";

const storageKey = "close-last-announcement";
const storageKeyLastAnnouncement = "last-announcement";

const Announcement: React.FC = () => {
  const [data, setData] = useState<AnnouncementMessage>();
  const [lastCloseAnnouncement, setLastCloseAnnouncement] = useState("");

  useEffect(() => {
    setLastCloseAnnouncement(localStorage.getItem(storageKey) ?? "");

    const lastAnnouncementString =
      localStorage.getItem(storageKeyLastAnnouncement) ?? "";
    if (lastAnnouncementString.length > 0) {
      setData(JSON.parse(lastAnnouncementString));
    }

    const unsub = onSnapshot(doc(db, "configs/announcement"), (snapshot) => {
      const newData = (snapshot.data() ?? {
        message: "",
        closable: true,
      }) as AnnouncementMessage;
      setData(newData);

      localStorage.setItem(storageKeyLastAnnouncement, JSON.stringify(newData));
    });

    return () => {
      unsub();
    };
  }, []);

  const onClose = useCallback(() => {
    const message = data?.message ?? "";
    localStorage.setItem(storageKey, message);
    setLastCloseAnnouncement(message);
  }, [data]);

  const hasData = !!(data?.message ?? "").trim();

  if (!hasData) return null;

  if (data?.closable && lastCloseAnnouncement === data?.message) return null;

  return (
    <div className={clsx("row ai-center", styles.container)}>
      <div className={styles.message}>{data?.message}</div>
      <Spacer flex={1} />
      {data?.closable && (
        <div
          className={clsx("cursor-pointer", styles.closeButton)}
          onClick={onClose}
        >
          <Image
            priority
            src={"/close.svg"}
            width={29}
            height={29}
            alt={"close"}
            layout={"fixed"}
          />
        </div>
      )}
    </div>
  );
};

export { Announcement };
