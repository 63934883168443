import React, { useMemo, useState } from "react";
import { MenuButton } from "./MenuButton";
import Spacer from "./Spacer";
import { Account } from "./Account";
import styles from "../styles/Header.module.scss";
import Image from "next/image";
import { Clippy } from "./Clippy";
import clsx from "clsx";
import { useScrolled } from "../utils/hooks";
import CartIcon from "./CartIcon";
import { Announcement } from "./Announcement";

export enum HeaderVariant {
  light,
  dark,
}
interface Props {
  variant: HeaderVariant;
  menuVariant?: HeaderVariant;
}

const Header: React.FC<Props> = ({ variant, menuVariant = variant }) => {
  const [view, setView] = useState("cart");
  const dark = useMemo(() => variant === HeaderVariant.dark, [variant]);
  const scrolled = useScrolled();

  return (
    <>
      <header
        className={clsx(styles.container, {
          [styles.darkContainer]: dark,
          [styles.scrolled]: scrolled,
        })}
      >
        <div className={clsx("row ai-center", styles.menu)}>
          <MenuButton variant={variant} containerClassName={"hideOnTablet"} />
          <Spacer flex={1} className={"hideOnTablet"} />
          <Account showConnectButton variant={variant} />
          <Spacer flex={1} className={"hideOnDesktop"} />
          <Spacer width={28} className={"hideOnTablet"} />
          <div className={"row"}>
            <div
              className={clsx(styles.button, { [styles.darkButton]: dark })}
              onClick={() => setView("how-it-works")}
            >
              <Image
                priority
                src={dark ? "/Clippy-green.svg" : "/Clippy.svg"}
                width={11}
                height={20}
                alt={"clippy icon"}
                layout={"fixed"}
              />
            </div>
            <div className={"hideOnDesktop row"}>
              <Spacer width={16} />
              <CartIcon variant={variant} />
            </div>
          </div>
        </div>
        <Announcement />
      </header>
      <div className={clsx(styles.menuContainer, "hideOnDesktop")}>
        <MenuButton variant={menuVariant} />
      </div>
      {view === "how-it-works" && <Clippy onClose={() => setView("cart")} />}
    </>
  );
};

export { Header };
