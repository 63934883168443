import React, { useContext, useMemo } from "react";
import styles from "styles/UpcomingFeaturesModal.module.scss";
import { useTranslation } from "react-i18next";
import Spacer from "./Spacer";
import Image from "next/image";
import { connectors } from "../fixtures/connectors";
import clsx from "clsx";
import { RCSAModal } from "./RCSAModal";
import { MediaQueryContext } from "../context/mediaQuery/context";

interface Props {
  onClose(): void;
}

const UpcomingFeaturesModal: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const {
    state: { tablet },
  } = useContext(MediaQueryContext);

  const features = useMemo(
    () => [
      {
        text: t("upcomingFeatures.ensSupport.text"),
        description: t("upcomingFeatures.ensSupport.description"),
      },
      {
        text: t("upcomingFeatures.xNFTProvenance.text"),
        description: t("upcomingFeatures.xNFTProvenance.description"),
      },
      {
        text: t("upcomingFeatures.secondarySales.text"),
        description: t("upcomingFeatures.secondarySales.description"),
      },
      {
        text: t("upcomingFeatures.verifiedXNFTUpgrade.text"),
        description: t("upcomingFeatures.verifiedXNFTUpgrade.description"),
      },
    ],
    [t]
  );

  return (
    <RCSAModal>
      <div className={"row ai-center"}>
        <div className={styles.title}>{t("upcomingFeatures.text")}</div>
        <Spacer flex={1} />
        <div onClick={onClose} className={"cursor-pointer"}>
          <Image
            priority
            src={"/close-green.svg"}
            width={tablet ? 22 : 29}
            height={tablet ? 22 : 29}
            layout={"fixed"}
            alt={"close"}
            className={"pointer-events-none"}
          />
        </div>
      </div>
      <Spacer height={24} />
      <p
        className={styles.agreementMessage}
        dangerouslySetInnerHTML={{
          __html: t("upcomingFeatures.message"),
        }}
      />
      <Spacer height={24} />
      {features.map(({ text, description }, index) => (
        <React.Fragment key={text}>
          <div className={clsx(styles.connector, "column user-select-none")}>
            <div className={styles.connectorName}>{text}</div>
            <div className={styles.description}>{description}</div>
          </div>
          {index !== connectors.length - 1 && <Spacer height={8} />}
        </React.Fragment>
      ))}
    </RCSAModal>
  );
};

export { UpcomingFeaturesModal };
