import React, { useCallback, useContext, useEffect, useState } from "react";
import { getExplorer } from "../utils/networks";
import { useMoralis } from "react-moralis";
import styles from "styles/AccountDetailsModal.module.scss";
import { useTranslation } from "react-i18next";
import Spacer from "./Spacer";
import Image from "next/image";
import Blockie from "./Blockie";
import { getEllipsisTxt } from "../utils/formatters";
import clsx from "clsx";
import { RCSAModal } from "./RCSAModal";
import { MediaQueryContext } from "../context/mediaQuery/context";

interface Props {
  show: boolean;
  balance: string | null;
  onClose(): void;
}
const AccountDetailsModal: React.FC<Props> = ({ show, balance, onClose }) => {
  const { account, chainId, logout, web3 } = useMoralis();
  const { t } = useTranslation();

  const onDisconnect = useCallback(async () => {
    await logout();
    window.localStorage.removeItem("connectorId");
    onClose();
  }, [logout, onClose]);

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (!show) {
      setCopied(false);
    }
  }, [show]);

  const {
    state: { tablet },
  } = useContext(MediaQueryContext);

  if (!show) return null;

  return (
    <RCSAModal>
      <div className={"row ai-center"}>
        <div className={styles.title}>{t("wallet.account")}</div>
        <Spacer flex={1} />
        <div className={"cursor-pointer"} onClick={onClose}>
          <Image
            priority
            src={"/close-green.svg"}
            width={tablet ? 22 : 29}
            height={tablet ? 22 : 29}
            layout={"fixed"}
            alt={"close"}
            className={"pointer-events-none"}
          />
        </div>
      </div>
      <Spacer height={45} className={"hideOnTablet"} />
      <Spacer height={24} className={"hideOnDesktop"} />
      <div className={styles.connectedWallet}>
        {`${t("wallet.connectedWith")} ${web3?.connection.url}`}
      </div>
      <Spacer height={29} className={"hideOnTablet"} />
      <Spacer height={24} className={"hideOnDesktop"} />
      <div className={styles.account}>
        <Blockie
          currentWallet
          scale={3}
          size={tablet ? 15.7 : 21}
          className={styles.blockie}
        />
        <Spacer width={16} />
        <div className={"column"}>
          <div className={styles.accountAddress}>
            {getEllipsisTxt(account ?? "", 6)}
          </div>
          <Spacer height={8} />
          <div className={styles.accountBalance}>
            {t("wallet.balance")}: {balance}
          </div>
        </div>
      </div>

      <Spacer height={16} />

      <div className={"row ai-center jc-center w-full"}>
        <div
          className={clsx("row ai-center", styles.button)}
          onClick={async () => {
            await navigator.clipboard.writeText(account ?? "");
            setCopied(true);
          }}
        >
          <Image
            priority
            src={copied ? "/check.svg" : "/copy.svg"}
            width={17}
            height={17}
            alt={"copy"}
            layout={"fixed"}
            objectFit={"contain"}
          />
          <Spacer width={4} />
          <div>
            {copied ? t("wallet.copiedAddress") : t("wallet.copyAddress")}
          </div>
        </div>
        <a
          href={`${getExplorer(chainId || "0x1")}/address/${account}`}
          target="_blank"
          rel="noreferrer"
          className={clsx("row ai-center", styles.button)}
        >
          <Image
            priority
            src={"/external-link.svg"}
            width={17}
            height={17}
            alt={"copy"}
          />
          <Spacer width={4} />
          <div>{t("wallet.viewOnExplorer")}</div>
        </a>
      </div>

      <Spacer height={32} className={"hideOnTablet"} />
      <Spacer height={24} className={"hideOnDesktop"} />
      <div className={styles.disconnectButton} onClick={onDisconnect}>
        {t("wallet.disconnectWallet")}
      </div>
    </RCSAModal>
  );
};

export { AccountDetailsModal };
