import React, { useContext, useMemo, useRef, useState } from "react";
import styles from "../styles/GetStarted.module.scss";
import Spacer from "./Spacer";
import clsx from "clsx";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import { useIsMounted } from "../utils/hooks";
import { a, useSpring } from "react-spring";
import Image from "next/image";
import { RCSAModal } from "./RCSAModal";
import { MediaQueryContext } from "../context/mediaQuery/context";

interface Props {
  onClose(): void;
}

const GetStarted: React.FC<Props> = ({ onClose }) => {
  const {
    state: { tablet },
  } = useContext(MediaQueryContext);
  const { t } = useTranslation();
  const [step, setStep] = useState(1);

  const videos = useMemo(
    () => [
      "https://storage.googleapis.com/rcsa-assets/right-click.mp4",
      "https://storage.googleapis.com/rcsa-assets/save-as.mp4",
      "https://storage.googleapis.com/rcsa-assets/own-it.mp4",
    ],
    []
  );

  return (
    <RCSAModal contentClassName={styles.content}>
      <div className={styles.getStartedContainer}>
        <div className={"rowDesktopColumnTablet"}>
          <div className={clsx("column jc-center", styles.leftPanel)}>
            <div className={clsx("column jc-center", styles.leftPanelContent)}>
              <div className={"row ai-center"}>
                <div className={styles.getStartedText}>
                  {t("home.getStarted")}
                </div>
                <Spacer flex={1} />
                <div
                  className={"hideOnDesktop cursor-pointer"}
                  onClick={onClose}
                >
                  <Image
                    priority
                    src={"/close-green.svg"}
                    width={22}
                    height={22}
                    alt={"close"}
                    layout={"fixed"}
                    className={"pointer-events-none"}
                  />
                </div>
              </div>
              <StepSection
                title={t("home.step1.text")}
                content={t("home.step1.content")}
                expanded={step === 1}
                onClick={() => setStep(1)}
                videoUrl={videos[0]}
              />
              <StepSection
                title={t("home.step2.text")}
                content={t("home.step2.content")}
                expanded={step === 2}
                onClick={() => setStep(2)}
                videoUrl={videos[1]}
              />
              <StepSection
                title={t("home.step3.text")}
                content={t("home.step3.content")}
                expanded={step === 3}
                onClick={() => setStep(3)}
                videoUrl={videos[2]}
              />
            </div>
            <div
              className={clsx(
                "rowDesktopColumnTablet ai-center",
                styles.getStartedFooter
              )}
            >
              <span className={styles.learnMoreMessage}>
                {t("home.learnMoreMessage")}
              </span>
              <Spacer flex={1} className={"hideOnTablet"} />
              <Spacer height={16} className={"hideOnDesktop"} />
              <Link href={"/about"}>
                <a className={"outlineLightButton"}>{t("home.learnMore")}</a>
              </Link>
            </div>
          </div>
          {!tablet && (
            <div className={styles.rightPanel}>
              <div className={styles.closeButton} onClick={onClose}>
                <Image
                  priority
                  src={"/close-green.svg"}
                  width={29}
                  height={29}
                  alt={"close"}
                  layout={"fixed"}
                  className={"pointer-events-none"}
                />
              </div>
              <div
                className={clsx("relative", styles.browserAndVideoContainer)}
              >
                <Image
                  priority
                  src={"/about/browser-header.png"}
                  alt={"browser"}
                  width={910}
                  height={52}
                />
                <div className={styles.videoContainer}>
                  <video src={videos[step - 1]} autoPlay muted loop />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </RCSAModal>
  );
};

export { GetStarted };

interface StepSectionProps {
  title: string;
  content: string;
  expanded: boolean;
  onClick(): void;
  videoUrl: string;
}

const StepSection: React.FC<StepSectionProps> = ({
  title,
  content,
  expanded,
  onClick,
  videoUrl,
}) => {
  const opened = expanded;
  const ref = useRef<HTMLDivElement>(null);
  const mounted = useIsMounted();
  const { height, opacity } = useSpring({
    from: { height: 0, opacity: 0 },
    to: {
      height: opened ? ref.current?.clientHeight ?? 0 : 0,
      opacity: opened ? 1 : 0,
    },
    immediate: !mounted,
  });
  const {
    state: { tablet },
  } = useContext(MediaQueryContext);

  return (
    <div
      className={clsx("column", styles.stepContainer, {
        [styles.stepContainerExpanded]: expanded,
      })}
    >
      <div className={styles.stepText} onClick={onClick}>
        {title}
      </div>
      {!expanded && <div className={styles.stepSeparator} />}

      <a.div
        style={{
          height: opened ? "auto" : height,
          opacity,
          overflow: (ref.current?.clientHeight ?? 0) > 50 ? "hidden" : "unset",
          pointerEvents: opened ? "auto" : "none",
        }}
      >
        <div
          ref={ref}
          className={styles.stepContent}
          dangerouslySetInnerHTML={{ __html: content }}
        />
        {tablet && (
          <>
            <div className={styles.mediaContainer}>
              <div className={styles.browserAndVideoContainer}>
                <Image
                  priority
                  src={"/about/browser-header.png"}
                  alt={"browser"}
                  width={910}
                  height={52}
                />
                <div className={styles.videoContainer}>
                  <video src={videoUrl} autoPlay muted loop playsInline />
                </div>
              </div>
            </div>
            <Spacer height={40} />
          </>
        )}
      </a.div>
    </div>
  );
};
