/* eslint-disable react/no-unescaped-entities */
import React, { useContext, useState } from "react";
import Image from "next/image";
import styles from "styles/Clippy.module.scss";
import Spacer from "./Spacer";
import clsx from "clsx";
import { useSpring } from "@react-spring/core";
import { animated, easings } from "react-spring";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import { RequireConnectWalletButton } from "./RequireConnectWalletButton";
import { UpcomingFeaturesModal } from "./UpcomingFeaturesModal";
import { SOJI_TWITTER } from "../utils/constants";
import packageJson from "../package.json";
import { GetStarted } from "./GetStarted";
import { MediaQueryContext } from "../context/mediaQuery/context";

const PANEL_WIDTH = 423;
interface Props {
  onClose(): void;
}

const Clippy: React.FC<Props> = ({ onClose }) => {
  const [closing, setClosing] = useState(false);
  const {
    state: { tablet },
  } = useContext(MediaQueryContext);
  const slideInAnimatedStyles = useSpring({
    from: { right: tablet ? -window.innerWidth : -PANEL_WIDTH },
    to: { right: 0 },
    config: { duration: 450, easing: easings.easeOutQuart },
  });

  const slideOutAnimatedStyles = useSpring({
    from: { right: 0 },
    to: { right: tablet ? -window.innerWidth : -PANEL_WIDTH },
    config: { duration: 450, easing: easings.easeOutQuart },
    reset: closing,
    pause: !closing,
    onRest: onClose,
  });

  const { t } = useTranslation();
  const router = useRouter();

  const [showHowItWorks, setShowHowItWorks] = useState(false);
  const [showUpcomingFeatures, setShowUpcomingFeatures] = useState(false);

  return (
    <>
      <animated.div
        className={styles.panel}
        style={closing ? slideOutAnimatedStyles : slideInAnimatedStyles}
      >
        <div className={"column h-full"}>
          <Spacer height={29} />
          <div className={clsx("row ai-center", styles.header)}>
            <div className={styles.clippyText}>{t("howItWorks.clippy")}</div>
            <Spacer flex={1} />
            <div
              className={clsx(
                "column ai-center jc-center cursor-pointer",
                styles.clippy
              )}
              onClick={() => setClosing(true)}
            >
              <Image
                priority
                src={"/Clippy-green.svg"}
                alt={"clippy icon"}
                layout={"fixed"}
                width={25.56}
                height={47.33}
              />
            </div>
          </div>
          <Spacer height={28} />
          <div className={styles.separator} />
          <div className={clsx("column", styles.content)}>
            <Spacer height={24} />
            <div className={styles.note}>{t("howItWorks.note")}</div>
            <Spacer height={24} />
            <Button
              text={t("howItWorks.text")}
              onPress={() => setShowHowItWorks(true)}
            />
            <Spacer height={8} />
            <RequireConnectWalletButton
              onClick={() => router.push("/my-masterpieces")}
              invokeOnClickAfterAuthenticated
            >
              <Button text={t("viewMasterpieces")} />
            </RequireConnectWalletButton>
            <Spacer height={8} />
            <Button
              text={t("upcomingFeatures.text")}
              onPress={() => setShowUpcomingFeatures(true)}
            />
            <Spacer height={8} />
            <Button
              text={t("bySOJI")}
              onPress={() => window.open(SOJI_TWITTER, "_blank")}
            />
            <Spacer height={24} />
            <div className={"row ai-center"}>
              <Spacer width={12.94} />
              <div className={styles.version}>
                {`${t("rcsa")} Version ${packageJson.version}`}
              </div>
            </div>
            <Spacer height={24} />
          </div>
          <Spacer flex={1} />
          <div className={styles.separator} />
          <Spacer height={14.96} />
          <div
            className={clsx("cursor-pointer", styles.closeButton)}
            onClick={() => setClosing(true)}
          >
            {t("close")}
          </div>
          <Spacer height={14} />
        </div>
      </animated.div>
      {showHowItWorks && (
        <GetStarted onClose={() => setShowHowItWorks(false)} />
      )}
      {showUpcomingFeatures && (
        <UpcomingFeaturesModal onClose={() => setShowUpcomingFeatures(false)} />
      )}
    </>
  );
};

interface ButtonProps {
  text: string;
  onPress?(): void;
  noArrow?: boolean;
}

const Button: React.FC<ButtonProps> = ({ text, onPress, noArrow = false }) => (
  <div className={clsx("row ai-center", styles.button)} onClick={onPress}>
    <div>{text}</div>
    {!noArrow && (
      <>
        <Spacer flex={1} />
        <div className={styles.buttonArrow}>
          <Image
            priority
            src={"/chevron-down-white.svg"}
            width={22}
            height={22}
            alt={"arrow right"}
          />
        </div>
      </>
    )}
  </div>
);

export { Clippy };
