import React from "react";
import Head from "next/head";
import { useTranslation } from "react-i18next";

interface Props {
  title?: string;
  description?: string;
  children?: React.ReactNode;
  useDefaultOpenGraph?: boolean;
}

const Meta: React.FC<Props> = ({
  title,
  children,
  description,
  useDefaultOpenGraph = true,
}) => {
  const { t } = useTranslation();
  const name = t("rcsa");
  const metaDescription = description ?? t("tagline");

  return (
    <Head>
      <title>{[title, name].filter(Boolean).join(" - ")}</title>

      <meta name="description" content={metaDescription} />
      <meta name="theme-color" content="#F7F3EA" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover"
      />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="#073937" />

      <link rel="icon" href="/favicon.ico" />
      <link rel="icon" type="image/png" href="/favicon-32x32.png" />
      <link rel="apple-touch-icon" href="/app_logo-200.png" />
      <link rel="shortcut icon" type="image/png" href="/icon-metamask.png" />
      <link rel="preconnect" href="https://ik.imagekit.io" />
      <link rel="preconnect" href="https://lh3.googleusercontent.com" />
      <link rel="preconnect" href="https://ipfs.io" />
      <link rel="preconnect" href="https://storage.googleapis.com" />

      {children}

      {useDefaultOpenGraph && (
        <>
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://rightclicksaveart.com" />
          <meta property="og:title" content="RIGHT CLICK SAVE ART" />
          <meta property="og:image" content="" />
          <meta property="og:description" content={metaDescription} />
          <meta property="og:site_name" content="RIGHT CLICK SAVE ART" />
          <meta property="og:image:width" content="1286" />
          <meta property="og:image:height" content="650" />
        </>
      )}
    </Head>
  );
};

export { Meta };
