import React, { useContext, useMemo } from "react";
import styles from "../styles/CartIcon.module.scss";
import { setSlidingIn } from "../context/cart/actions";
import Image from "next/image";
import { CartContext } from "../context/cart/context";
import { HeaderVariant } from "./Header";
import clsx from "clsx";

interface Props {
  variant: HeaderVariant;
}

const CartIcon: React.FC<Props> = ({ variant }) => {
  const cart = useContext(CartContext);
  const dark = useMemo(() => variant === HeaderVariant.dark, [variant]);

  return (
    <div
      className={clsx(styles.container, { [styles.darkContainer]: dark })}
      onClick={() => cart.dispatch(setSlidingIn(true))}
    >
      <Image
        priority
        src={dark ? "/cart-green.svg" : "/cart.svg"}
        width={16}
        height={16}
        alt={"cart icon"}
        layout={"fixed"}
      />
    </div>
  );
};

export default CartIcon;
