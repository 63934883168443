import Blockies from "react-blockies";
import { useMoralis } from "react-moralis";
import React from "react";

interface Props {
  currentWallet?: any;
  scale?: number;
  className?: string;
  address?: string;
  size?: number;
}

const Blockie: React.FC<Props> = (props) => {
  const { account } = useMoralis();

  return (
    <Blockies
      seed={
        (props.currentWallet
          ? account?.toLowerCase()
          : props.address?.toLowerCase()) ?? ""
      }
      {...props}
    />
  );
};
export default Blockie;
